var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn w-100"
  }, [_vm.isWidget ? _c('div', _vm._l(_vm.usersData, function (reportData, index) {
    return _c('div', {
      key: "div-".concat(index)
    }, _vm._l(reportData.weeks, function (week, index2) {
      return _c('div', {
        key: "card-".concat(index2),
        attrs: {
          "header-bg-variant": "transparent"
        }
      }, [_c('b-table', {
        attrs: {
          "fields": _vm.detailsColumns,
          "items": week.details,
          "small": "",
          "responsive": ""
        },
        scopedSlots: _vm._u([{
          key: "cell(lunch_hours)",
          fn: function fn(data) {
            return [!data.item.errors.includes(this.$constants.TIMELOG_ERRORS.NO_LUNCH) ? _c('span', [_vm._v(_vm._s(data.value))]) : _vm._e(), data.item.errors.includes(this.$constants.TIMELOG_ERRORS.NO_LUNCH) ? _c('b-button', {
              staticClass: "p-0",
              attrs: {
                "variant": "outline-ligth"
              },
              on: {
                "click": function click($event) {
                  return _vm.addLunch(data);
                }
              }
            }, [_c('font-awesome-icon', {
              attrs: {
                "icon": "utensils"
              }
            })], 1) : _vm._e()];
          }
        }, {
          key: "cell()",
          fn: function fn(data) {
            return [_vm._v(" " + _vm._s(data.value) + " ")];
          }
        }], null, true)
      })], 1);
    }), 0);
  }), 0) : _vm._e(), _c('b-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isWidget,
      expression: "!isWidget"
    }]
  }, [_c('b-card-body', [_c('b-row', {
    staticClass: "exclude-print"
  }, [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "client",
      "dataset-name": "time-tracker-payroll",
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries,
      "show-labels": false,
      "compact": false,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": _vm.onFilteringPanelSearch,
      "reset": _vm.onFilteringPanelReset,
      "dict-loaded": _vm.onDictionariesLoaded
    }
  })], 1)], 1), _c('hr'), _vm.isLoading ? _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "secondary"
    }
  })], 1)])], 1) : _vm._e(), !_vm.isLoading && !_vm.isWidget ? _c('b-row', {
    ref: "content"
  }, [_c('b-col', [_vm._l(_vm.usersData, function (reportData, userIndex) {
    return _c('div', {
      key: "div-".concat(userIndex)
    }, [reportData.salaryTable.data.length > 0 && !_vm.hideEmptyUsers(reportData) ? _c('div', [_c('b-table', {
      staticClass: "user-header-no-print",
      attrs: {
        "fields": _vm.salaryTableColumns,
        "items": reportData.salaryTable.data,
        "dark": "dark",
        "table-variant": "dark",
        "head-variant": "dark",
        "responsive": ""
      }
    }), _c('div', {
      staticClass: "user-header-print mb-3 font-weight-bold"
    }, _vm._l(_vm.salaryTableColumns, function (col) {
      return _c('span', {
        key: col.key,
        staticClass: "m-3"
      }, [_vm._v(_vm._s(reportData.salaryTable.data[0][col.key]))]);
    }), 0)], 1) : _vm._e(), _vm._l(reportData.weeks.filter(function (w) {
      return w.grossPay > 0;
    }), function (week, index) {
      var _week$vacationUnpaidH, _week$PLAUnpaidHours;
      return _c('b-card', {
        key: "card-".concat(index),
        attrs: {
          "header-bg-variant": "transparent"
        }
      }, [_c('h5', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle",
          value: 'collapse-day',
          expression: "'collapse-day'"
        }],
        staticStyle: {
          "cursor": "pointer"
        }
      }, [week.errors.length > 0 ? _c('font-awesome-icon', {
        staticStyle: {
          "color": "red"
        },
        attrs: {
          "icon": "exclamation-circle"
        }
      }) : _vm._e(), _vm._v(" Period: " + _vm._s(week.period) + " ")], 1), _c('b-row', {
        staticClass: "exclude-print"
      }, _vm._l(week.errors, function (e, index2) {
        return _c('b-col', {
          key: "col-".concat(index2),
          attrs: {
            "lg": "2"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v(" " + _vm._s(_vm.$constants.timeLogErrorsOptions.find(function (i) {
          return i.id == e;
        }).label) + " ")])], 1);
      }), 1), _c('b-collapse', {
        staticClass: "mt-2",
        attrs: {
          "id": 'collapse-day',
          "accordion": "my-accordion"
        }
      }, [_c('b-card', [_c('b-table', {
        attrs: {
          "fields": _vm.detailsColumns,
          "items": week.details,
          "responsive": ""
        },
        scopedSlots: _vm._u([{
          key: "cell(time_stamp1)",
          fn: function fn(data) {
            return [data.value ? _c('inline-date-picker', {
              attrs: {
                "id": "".concat(data.item.trackid, "-controls.from.datetime-").concat(index),
                "value-single": data.value,
                "hide-label": true,
                "show-time": true,
                "readonly": _vm.mode == 'mypayroll' && _vm.getDate(data.value) <= _vm.lastPayrollDate,
                "mode": _vm.$constants.FORM_MODE.VIEW
              },
              on: {
                "changed": function changed($event) {
                  return _vm.updateDateField($event, data);
                }
              }
            }) : _vm._e()];
          }
        }, {
          key: "cell(time_stamp2)",
          fn: function fn(data) {
            return [data.value || data.item.day_type == 'Regular' ? _c('inline-date-picker', {
              attrs: {
                "id": "".concat(data.item.trackid, "-controls.to.datetime-").concat(index),
                "value-single": data.value,
                "hide-label": true,
                "show-time": true,
                "readonly": _vm.mode == 'mypayroll' && _vm.getDate(data.value) <= _vm.lastPayrollDate,
                "mode": _vm.$constants.FORM_MODE.VIEW
              },
              on: {
                "changed": function changed($event) {
                  return _vm.updateDateField($event, data);
                }
              }
            }) : _vm._e()];
          }
        }, {
          key: "cell(lunch_hours)",
          fn: function fn(data) {
            return [!data.item.errors.includes(_vm.$constants.TIMELOG_ERRORS.NO_LUNCH) ? _c('span', [_vm._v(_vm._s(data.value))]) : _vm._e(), data.item.errors.includes(_vm.$constants.TIMELOG_ERRORS.NO_LUNCH) ? _c('b-button', {
              staticClass: "p-0",
              attrs: {
                "variant": "outline-ligth"
              },
              on: {
                "click": function click($event) {
                  return _vm.addLunch(data);
                }
              }
            }, [_c('font-awesome-icon', {
              attrs: {
                "icon": "utensils"
              }
            })], 1) : _vm._e()];
          }
        }, {
          key: "cell()",
          fn: function fn(data) {
            return [_vm._v(" " + _vm._s(data.value) + " ")];
          }
        }, {
          key: "cell(actions)",
          fn: function fn(data) {
            return [_vm.profile.data.role == 'H2' && !['Holiday', 'Vacation'].includes(data.item.day_type) && !data.item.trackid ? _c('b-button', {
              attrs: {
                "size": "sm",
                "variant": "success",
                "title": "Add time"
              },
              on: {
                "click": function click($event) {
                  return _vm.onAddTimeClick(data.item, reportData.salaryTable.data);
                }
              }
            }, [_c('font-awesome-icon', {
              attrs: {
                "icon": "plus"
              }
            })], 1) : _vm._e(), _vm.profile.data.role == 'H2' && !['Holiday', 'Vacation'].includes(data.item.day_type) && data.item.trackid ? _c('b-button', {
              attrs: {
                "size": "sm",
                "variant": "danger",
                "title": "Delete record"
              },
              on: {
                "click": function click($event) {
                  return _vm.onDeleteTimeClick(data.item, reportData);
                }
              }
            }, [_c('font-awesome-icon', {
              attrs: {
                "icon": "trash"
              }
            })], 1) : _vm._e()];
          }
        }], null, true)
      })], 1)], 1), _c('b-card-text', [reportData.salary.yearlyRate == 0 ? _c('b-row', [_c('b-col', [_c('div', [_vm._v(" Regular: " + _vm._s(week.regularHours.toFixed(2)) + " (h) x $" + _vm._s(reportData.salary.hourlyRate) + " ")]), _c('div', [_vm._v(" Overtime: " + _vm._s(week.overtimeHours.toFixed(2)) + " x $" + _vm._s(reportData.salary.hourlyRate) + " x 1.5 ")]), _c('div', {
        class: [!week.sickPaidHours ? 'exclude-print' : '']
      }, [_vm._v(" Paid sick: " + _vm._s(week.sickPaidHours.toFixed(2)) + " (h) x $" + _vm._s(reportData.salary.hourlyRate) + " ")]), _c('div', {
        class: [!week.vacationPaidHours ? 'exclude-print' : '']
      }, [_vm._v(" Paid vacation: " + _vm._s(week.vacationPaidHours.toFixed(2)) + " (h) x $" + _vm._s(reportData.salary.hourlyRate) + " ")]), _c('div', {
        class: [!week.PLAPaidHours ? 'exclude-print' : '']
      }, [_vm._v(" Paid PLA: " + _vm._s(week.PLAPaidHours.toFixed(2)) + " (h) x $" + _vm._s(reportData.salary.hourlyRate) + " ")]), _c('div', {
        class: [!week.holidayHours ? 'exclude-print' : '']
      }, [_vm._v(" Holiday: " + _vm._s(week.holidayHours.toFixed(2)) + " (h) x $" + _vm._s(reportData.salary.hourlyRate) + " ")]), _c('div', [_c('h6', [_vm._v(" Total paid time: " + _vm._s(week.totalPaidHours.toFixed(2)) + " (h) ")])]), _c('hr', {
        staticClass: "mt-0 mb-0"
      })]), _c('b-col', [_c('div', {
        class: [!week.lunchHours ? 'exclude-print' : '']
      }, [_vm._v(" Lunch: " + _vm._s(week.lunchHours.toFixed(2)) + " (h) ")]), _c('div', {
        class: [!week.sickUnpaidHours ? 'exclude-print' : '']
      }, [_vm._v(" Unpaid sick: " + _vm._s(week.sickUnpaidHours.toFixed(2)) + " (h) ")]), _c('div', {
        class: [!week.vacationPaidHours ? 'exclude-print' : '']
      }, [_vm._v(" Unpaid vacation: " + _vm._s((_week$vacationUnpaidH = week.vacationUnpaidHours) === null || _week$vacationUnpaidH === void 0 ? void 0 : _week$vacationUnpaidH.toFixed(2)) + " (h) ")]), _c('div', {
        class: [!week.PLAUnpaidHours ? 'exclude-print' : '']
      }, [_vm._v(" Unpaid PLA: " + _vm._s((_week$PLAUnpaidHours = week.PLAUnpaidHours) === null || _week$PLAUnpaidHours === void 0 ? void 0 : _week$PLAUnpaidHours.toFixed(2)) + " (h) ")]), _c('div', {
        domProps: {
          "innerHTML": _vm._s('&nbsp;')
        }
      }), _c('div', {
        class: [!week.totalUnpaidHours ? 'exclude-print' : '']
      }, [_c('h6', [_vm._v(" Total unpaid time: " + _vm._s(week.totalUnpaidHours.toFixed(2)) + " (h) ")])])])], 1) : _vm._e(), reportData.salary.yearlyRate > 0 ? _c('b-row', [_c('b-col', [_c('div', [_vm._v("Weekly Pay: $" + _vm._s(week.weeklyPay.toFixed(2)))])])], 1) : _vm._e(), _c('b-row', [_c('b-col', [reportData.salary.yearlyRate == 0 ? _c('span', [_c('div', [_vm._v("Pay: $" + _vm._s(week.pay.toFixed(2)))]), _c('div', [_vm._v(" Overtime pay: $" + _vm._s(week.overtimePay.toFixed(2)) + " ")])]) : _vm._e(), _c('h5', [_vm._v(" Reimbursement: $" + _vm._s(week.reimbursement.toFixed(2)) + " ")]), _c('h5', [_vm._v(" Pay additions: $" + _vm._s(week.payAdditions.toFixed(2)) + " ")]), _c('h5', [_vm._v(" Bonus payments: $" + _vm._s(week.bonusPayments.toFixed(2)) + " ")]), _c('h5', [_vm._v("Gross pay: $" + _vm._s(week.grossPay.toFixed(2)))])])], 1)], 1)], 1);
    }), !_vm.hideEmptyUsers(reportData) ? _c('div', [_vm._v("Total by user:")]) : _vm._e(), !_vm.hideEmptyUsers(reportData) ? _c('b-card', {
      attrs: {
        "bg-variant": "light"
      }
    }, [_c('b-card-text', {
      staticClass: "avoid-page-break"
    }, [_c('h5', [_vm._v(" Total Gross Pay: $" + _vm._s(reportData.total.totalGrossPay.toFixed(2)) + " ")]), _c('h5', [_vm._v(" Total Reimbursement: $" + _vm._s(reportData.total.totalReimbursement.toFixed(2)) + " ")]), _c('h5', [_vm._v(" Total Pay Additions: $" + _vm._s(reportData.total.totalPayAdditions.toFixed(2)) + " ")]), _c('h5', [_vm._v(" Total Bonus Payments: $" + _vm._s(reportData.total.totalBonusPayments.toFixed(2)) + " ")]), _c('div', [_vm._v(" Total Pay: $" + _vm._s(reportData.total.totalPay.toFixed(2)) + " ")]), _c('div', [_vm._v(" Total Overtime: $" + _vm._s(reportData.total.totalOvertime.toFixed(2)) + " ")]), _c('div', [_vm._v(" Total Regular hr: " + _vm._s(reportData.total.totalRegularHours.toFixed(2)) + " ")]), _c('div', [_vm._v(" Total Overtime hr: " + _vm._s(reportData.total.totalOvertimeHours.toFixed(2)) + " ")]), _c('div', [_vm._v(" Total Vacation hr (paid/unpaid): " + _vm._s(reportData.total.totalVacationPaidHours.toFixed(2)) + " / " + _vm._s(reportData.total.totalVacationUnpaidHours.toFixed(2)) + " (h) x $" + _vm._s(reportData.salary.hourlyRate) + " ")]), _c('div', [_vm._v(" Total PLA hr (paid/unpaid): " + _vm._s(reportData.total.totalPLAPaidHours.toFixed(2)) + " / " + _vm._s(reportData.total.totalPLAUnpaidHours.toFixed(2)) + " (h) x $" + _vm._s(reportData.salary.hourlyRate) + " ")]), _c('div', [_vm._v(" Total Sick hr (paid/unpaid): " + _vm._s(reportData.total.totalSickPaidHours.toFixed(2)) + " / " + _vm._s(reportData.total.totalSickUnPaidHours.toFixed(2)) + " (h) x $" + _vm._s(reportData.salary.hourlyRate) + " ")]), _c('div', [_vm._v(" Total Holiday hr: " + _vm._s(reportData.total.totalHolidayHours.toFixed(2)) + " (h) x $" + _vm._s(reportData.salary.hourlyRate) + " ")])])], 1) : _vm._e()], 2);
  }), _vm.usersData.length > 0 && this.mode !== 'mypayroll' ? _c('div', [_vm._v(" Total by report: "), _c('b-card', {
    attrs: {
      "bg-variant": "white"
    }
  }, [_c('b-card-text', [_c('h5', [_vm._v(" Total Gross Pay: $" + _vm._s(_vm.reportTotal.totalGrossPay.toFixed(2)) + " ")]), _c('h5', [_vm._v(" Total Reimbursement: $" + _vm._s(_vm.reportTotal.totalReimbursement.toFixed(2)) + " ")]), _c('h5', [_vm._v(" Total Pay Additions: $" + _vm._s(_vm.reportTotal.totalPayAdditions.toFixed(2)) + " ")]), _c('h5', [_vm._v(" Total Bonus Payments: $" + _vm._s(_vm.reportTotal.totalBonusPayments.toFixed(2)) + " ")]), _c('div', [_vm._v("Total Pay: $" + _vm._s(_vm.reportTotal.totalPay.toFixed(2)))]), _c('div', [_vm._v(" Total Overtime: $" + _vm._s(_vm.reportTotal.totalOvertime.toFixed(2)) + " ")]), _c('div', [_vm._v("Total Exempt users: " + _vm._s(_vm.reportTotal.totalExempt))]), _c('div', [_vm._v(" Total Non-exempt users: " + _vm._s(_vm.reportTotal.totalNonExempt) + " ")]), _c('div', [_vm._v(" Total Regular hr: " + _vm._s(_vm.reportTotal.totalRegularHours) + " ")]), _c('div', [_vm._v(" Total Overtime hr: " + _vm._s(_vm.reportTotal.totalOvertimeHours.toFixed(2)) + " ")])])], 1)], 1) : _vm._e()], 2)], 1) : _vm._e()], 1)], 1), _c('add-time-dialog', {
    ref: "addTimeDialog",
    on: {
      "save": _vm.onTimeDialogSave
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }