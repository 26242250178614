<template>
  <div class="animated fadeIn w-100">
    <div v-if="isWidget">
      <div v-for="(reportData, index) in usersData" :key="`div-${index}`">
        <div
          v-for="(week, index2) in reportData.weeks"
          header-bg-variant="transparent"
          :key="`card-${index2}`"
        >
          <b-table
            :fields="detailsColumns"
            :items="week.details"
            small
            responsive
          >
            <template #cell(lunch_hours)="data">
              <span
                v-if="
                  !data.item.errors.includes(
                    this.$constants.TIMELOG_ERRORS.NO_LUNCH
                  )
                "
                >{{ data.value }}</span
              >
              <b-button
                variant="outline-ligth"
                class="p-0"
                v-if="
                  data.item.errors.includes(
                    this.$constants.TIMELOG_ERRORS.NO_LUNCH
                  )
                "
                @click="addLunch(data)"
              >
                <font-awesome-icon icon="utensils" />
              </b-button>
            </template>
            <template #cell()="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <b-card v-show="!isWidget">
      <b-card-body>
        <b-row class="exclude-print">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="time-tracker-payroll"
              :loaded="filteringPanel.loaded"
              :load-dictionaries="loadDictionaries"
              :show-labels="false"
              :compact="false"
              :filters="filteringPanel.filters"
              @change="onFilteringPanelChange"
              @search="onFilteringPanelSearch"
              @reset="onFilteringPanelReset"
              @dict-loaded="onDictionariesLoaded"
            />
          </b-col>
        </b-row>
        <hr />

        <b-row v-if="isLoading">
          <b-col>
            <div class="text-center">
              <b-spinner variant="secondary"> </b-spinner>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!isLoading && !isWidget" ref="content">
          <b-col>
            <div
              v-for="(reportData, userIndex) in usersData"
              :key="`div-${userIndex}`"
            >
              <div
                v-if="
                  reportData.salaryTable.data.length > 0 &&
                    !hideEmptyUsers(reportData)
                "
              >
                <b-table
                  class="user-header-no-print"
                  :fields="salaryTableColumns"
                  :items="reportData.salaryTable.data"
                  dark="dark"
                  table-variant="dark"
                  head-variant="dark"
                  responsive
                />

                <div class="user-header-print mb-3 font-weight-bold">
                  <span
                    :key="col.key"
                    v-for="col in salaryTableColumns"
                    class="m-3"
                    >{{ reportData.salaryTable.data[0][col.key] }}</span
                  >
                </div>
              </div>

              <b-card
                v-for="(week, index) in reportData.weeks.filter(
                  w => w.grossPay > 0
                )"
                header-bg-variant="transparent"
                :key="`card-${index}`"
              >
                <h5 v-b-toggle="'collapse-day'" style="cursor: pointer">
                  <font-awesome-icon
                    v-if="week.errors.length > 0"
                    icon="exclamation-circle"
                    style="color: red"
                  />
                  Period: {{ week.period }}
                </h5>
                <b-row class="exclude-print">
                  <b-col
                    lg="2"
                    v-for="(e, index2) in week.errors"
                    :key="`col-${index2}`"
                  >
                    <b-alert show variant="warning">
                      {{
                        $constants.timeLogErrorsOptions.find(i => i.id == e)
                          .label
                      }}
                    </b-alert>
                  </b-col>
                </b-row>

                <b-collapse
                  :id="'collapse-day'"
                  accordion="my-accordion"
                  class="mt-2"
                >
                  <b-card>
                    <!-- `user-${reportData.salaryTable.data[0].salaryreluserid}-time-table` -->

                    <b-table
                      :fields="detailsColumns"
                      :items="week.details"
                      responsive
                    >
                      <template #cell(time_stamp1)="data">
                        <inline-date-picker
                          v-if="data.value"
                          :id="
                            `${data.item.trackid}-controls.from.datetime-${index}`
                          "
                          :value-single="data.value"
                          :hide-label="true"
                          :show-time="true"
                          :readonly="
                            mode == 'mypayroll' &&
                              getDate(data.value) <= lastPayrollDate
                          "
                          :mode="$constants.FORM_MODE.VIEW"
                          @changed="updateDateField($event, data)"
                        />
                      </template>
                      <template #cell(time_stamp2)="data">
                        <inline-date-picker
                          v-if="data.value || data.item.day_type == 'Regular'"
                          :id="
                            `${data.item.trackid}-controls.to.datetime-${index}`
                          "
                          :value-single="data.value"
                          :hide-label="true"
                          :show-time="true"
                          :readonly="
                            mode == 'mypayroll' &&
                              getDate(data.value) <= lastPayrollDate
                          "
                          :mode="$constants.FORM_MODE.VIEW"
                          @changed="updateDateField($event, data)"
                        />
                      </template>

                      <template #cell(lunch_hours)="data">
                        <span
                          v-if="
                            !data.item.errors.includes(
                              $constants.TIMELOG_ERRORS.NO_LUNCH
                            )
                          "
                          >{{ data.value }}</span
                        >
                        <b-button
                          variant="outline-ligth"
                          class="p-0"
                          v-if="
                            data.item.errors.includes(
                              $constants.TIMELOG_ERRORS.NO_LUNCH
                            )
                          "
                          @click="addLunch(data)"
                        >
                          <font-awesome-icon icon="utensils" />
                        </b-button>
                      </template>

                      <template #cell()="data">
                        {{ data.value }}
                      </template>

                      <template #cell(actions)="data">
                        <b-button
                          v-if="
                            profile.data.role == 'H2' &&
                              !['Holiday', 'Vacation'].includes(
                                data.item.day_type
                              ) &&
                              !data.item.trackid
                          "
                          size="sm"
                          variant="success"
                          title="Add time"
                          @click="
                            onAddTimeClick(
                              data.item,
                              reportData.salaryTable.data
                            )
                          "
                        >
                          <font-awesome-icon icon="plus" />
                        </b-button>

                        <b-button
                          v-if="
                            profile.data.role == 'H2' &&
                              !['Holiday', 'Vacation'].includes(
                                data.item.day_type
                              ) &&
                              data.item.trackid
                          "
                          size="sm"
                          variant="danger"
                          title="Delete record"
                          @click="onDeleteTimeClick(data.item, reportData)"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                      </template>
                    </b-table>
                  </b-card>
                </b-collapse>
                <b-card-text>
                  <b-row v-if="reportData.salary.yearlyRate == 0">
                    <b-col>
                      <div>
                        Regular: {{ week.regularHours.toFixed(2) }} (h) x ${{
                          reportData.salary.hourlyRate
                        }}
                      </div>
                      <div>
                        Overtime: {{ week.overtimeHours.toFixed(2) }} x ${{
                          reportData.salary.hourlyRate
                        }}
                        x 1.5
                      </div>
                      <div
                        :class="[!week.sickPaidHours ? 'exclude-print' : '']"
                      >
                        Paid sick: {{ week.sickPaidHours.toFixed(2) }} (h) x ${{
                          reportData.salary.hourlyRate
                        }}
                      </div>
                      <div
                        :class="[
                          !week.vacationPaidHours ? 'exclude-print' : ''
                        ]"
                      >
                        Paid vacation:
                        {{ week.vacationPaidHours.toFixed(2) }} (h) x ${{
                          reportData.salary.hourlyRate
                        }}
                      </div>
                      <div :class="[!week.PLAPaidHours ? 'exclude-print' : '']">
                        Paid PLA:
                        {{ week.PLAPaidHours.toFixed(2) }} (h) x ${{
                          reportData.salary.hourlyRate
                        }}
                      </div>
                      <div :class="[!week.holidayHours ? 'exclude-print' : '']">
                        Holiday: {{ week.holidayHours.toFixed(2) }} (h) x ${{
                          reportData.salary.hourlyRate
                        }}
                      </div>
                      <div>
                        <h6>
                          Total paid time:
                          {{ week.totalPaidHours.toFixed(2) }} (h)
                        </h6>
                      </div>
                      <hr class="mt-0 mb-0" />
                    </b-col>
                    <b-col>
                      <div :class="[!week.lunchHours ? 'exclude-print' : '']">
                        Lunch: {{ week.lunchHours.toFixed(2) }} (h)
                      </div>
                      <div
                        :class="[!week.sickUnpaidHours ? 'exclude-print' : '']"
                      >
                        Unpaid sick: {{ week.sickUnpaidHours.toFixed(2) }} (h)
                      </div>
                      <div
                        :class="[
                          !week.vacationPaidHours ? 'exclude-print' : ''
                        ]"
                      >
                        Unpaid vacation:
                        {{ week.vacationUnpaidHours?.toFixed(2) }} (h)
                      </div>
                      <div
                        :class="[!week.PLAUnpaidHours ? 'exclude-print' : '']"
                      >
                        Unpaid PLA:
                        {{ week.PLAUnpaidHours?.toFixed(2) }} (h)
                      </div>
                      <div v-html="'&nbsp;'" />
                      <div
                        :class="[!week.totalUnpaidHours ? 'exclude-print' : '']"
                      >
                        <h6>
                          Total unpaid time:
                          {{ week.totalUnpaidHours.toFixed(2) }} (h)
                        </h6>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row v-if="reportData.salary.yearlyRate > 0">
                    <b-col>
                      <div>Weekly Pay: ${{ week.weeklyPay.toFixed(2) }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span v-if="reportData.salary.yearlyRate == 0">
                        <div>Pay: ${{ week.pay.toFixed(2) }}</div>
                        <div>
                          Overtime pay: ${{ week.overtimePay.toFixed(2) }}
                        </div>
                      </span>
                      <h5>
                        Reimbursement: ${{ week.reimbursement.toFixed(2) }}
                      </h5>
                      <h5>
                        Pay additions: ${{ week.payAdditions.toFixed(2) }}
                      </h5>
                      <h5>
                        Bonus payments: ${{ week.bonusPayments.toFixed(2) }}
                      </h5>
                      <h5>Gross pay: ${{ week.grossPay.toFixed(2) }}</h5>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
              <div v-if="!hideEmptyUsers(reportData)">Total by user:</div>
              <b-card bg-variant="light" v-if="!hideEmptyUsers(reportData)">
                <b-card-text class="avoid-page-break">
                  <h5>
                    Total Gross Pay: ${{
                      reportData.total.totalGrossPay.toFixed(2)
                    }}
                  </h5>
                  <h5>
                    Total Reimbursement: ${{
                      reportData.total.totalReimbursement.toFixed(2)
                    }}
                  </h5>
                  <h5>
                    Total Pay Additions: ${{
                      reportData.total.totalPayAdditions.toFixed(2)
                    }}
                  </h5>
                  <h5>
                    Total Bonus Payments: ${{
                      reportData.total.totalBonusPayments.toFixed(2)
                    }}
                  </h5>
                  <div>
                    Total Pay: ${{ reportData.total.totalPay.toFixed(2) }}
                  </div>
                  <div>
                    Total Overtime: ${{
                      reportData.total.totalOvertime.toFixed(2)
                    }}
                  </div>
                  <div>
                    Total Regular hr:
                    {{ reportData.total.totalRegularHours.toFixed(2) }}
                  </div>
                  <div>
                    Total Overtime hr:
                    {{ reportData.total.totalOvertimeHours.toFixed(2) }}
                  </div>

                  <div>
                    Total Vacation hr (paid/unpaid):
                    {{ reportData.total.totalVacationPaidHours.toFixed(2) }} /
                    {{ reportData.total.totalVacationUnpaidHours.toFixed(2) }}
                    (h) x ${{ reportData.salary.hourlyRate }}
                    <!--
                    {{ reportData.total.totalVacationHours.toFixed(2) }} (h) x
                    ${{ reportData.salary.hourlyRate }}
                    -->
                  </div>
                  <div>
                    Total PLA hr (paid/unpaid):
                    {{ reportData.total.totalPLAPaidHours.toFixed(2) }} /
                    {{ reportData.total.totalPLAUnpaidHours.toFixed(2) }}
                    (h) x ${{ reportData.salary.hourlyRate }}
                  </div>
                  <div>
                    Total Sick hr (paid/unpaid):
                    {{ reportData.total.totalSickPaidHours.toFixed(2) }} /
                    {{ reportData.total.totalSickUnPaidHours.toFixed(2) }} (h) x
                    ${{ reportData.salary.hourlyRate }}
                  </div>
                  <div>
                    Total Holiday hr:
                    {{ reportData.total.totalHolidayHours.toFixed(2) }} (h) x
                    ${{ reportData.salary.hourlyRate }}
                  </div>
                </b-card-text>
              </b-card>
            </div>

            <div v-if="usersData.length > 0 && this.mode !== 'mypayroll'">
              Total by report:
              <b-card bg-variant="white">
                <b-card-text>
                  <h5>
                    Total Gross Pay: ${{ reportTotal.totalGrossPay.toFixed(2) }}
                  </h5>
                  <h5>
                    Total Reimbursement: ${{
                      reportTotal.totalReimbursement.toFixed(2)
                    }}
                  </h5>
                  <h5>
                    Total Pay Additions: ${{
                      reportTotal.totalPayAdditions.toFixed(2)
                    }}
                  </h5>
                  <h5>
                    Total Bonus Payments: ${{
                      reportTotal.totalBonusPayments.toFixed(2)
                    }}
                  </h5>
                  <div>Total Pay: ${{ reportTotal.totalPay.toFixed(2) }}</div>
                  <div>
                    Total Overtime: ${{ reportTotal.totalOvertime.toFixed(2) }}
                  </div>
                  <div>Total Exempt users: {{ reportTotal.totalExempt }}</div>
                  <div>
                    Total Non-exempt users: {{ reportTotal.totalNonExempt }}
                  </div>
                  <div>
                    Total Regular hr: {{ reportTotal.totalRegularHours }}
                  </div>
                  <div>
                    Total Overtime hr:
                    {{ reportTotal.totalOvertimeHours.toFixed(2) }}
                  </div>
                </b-card-text>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <add-time-dialog ref="addTimeDialog" @save="onTimeDialogSave" />
  </div>
</template>

<script>
//import Vue from 'vue';
import Moment from 'moment'
import AddTimeDialog from '@/components/AddTimeDialog'

import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)

import FilteringPanel from '@/components/FilteringPanel'

import InlineDatePicker from '@/components/InlineDatePicker'

import { mapState, mapActions } from 'vuex'

export default {
  props: ['startDate', 'endDate', 'run', 'isWidget'],
  components: {
    FilteringPanel,

    InlineDatePicker,
    AddTimeDialog
  },
  data: function () {
    return {
      testKey: '',
      mode: '',
      isLoading: false,
      rawData: {},
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: 'select',
            dataType: 'string',
            title: 'User Name',
            tooltip: 'User name',
            name: 'user_name',
            trackby: 'id',
            label: 'name',
            multiple: false,
            options: [],
            selected: {}
          },
          {
            type: 'select',
            dataType: 'string',
            title: 'Job Role',
            tooltip: 'Job Role',
            name: 'filtrole',
            trackby: 'id',
            label: 'name',
            multiple: false,
            options: [],
            selected: {}
          },
          //RGB need to join group in SQL
          // {
          //   type: "select",
          //   dataType: "string",
          //   title: "Group",
          //   tooltip: "Group",
          //   name: "filtgroup",
          //   trackby: "id",
          //   label: "name",
          //   multiple: false,
          //   options: [],
          //   selected: {}
          // },
          //RGB no point in filtering for inactive users
          //  {
          //   type: "select",
          //   dataType: "string",
          //   title: "Status",
          //   name: "umng_user_status",
          //   trackby: "id",
          //   label: "label",
          //   options: [
          //     { id: "Active", label: "Active" },
          //     { id: "Inactive", label: "Inactive" }
          //   ],
          //   selected: {},
          //   multiple: true
          // },
          {
            type: 'select',
            dataType: 'string',
            title: 'User Category',
            name: 'filtcat',
            trackby: 'id',
            label: 'name',
            options: [
              { id: 'Exempt', name: 'Exempt' },
              //{ id: "Hourly", name: "Hourly" },
              { id: 'Non-exempt', name: 'Non-exempt' },
              { id: 'None', name: 'None' }
            ],
            selected: {},
            multiple: false
          },
          {
            type: 'select',
            dataType: 'string',
            title: 'Emp Status',
            name: 'filtempstatus',
            trackby: 'id',
            label: 'name',
            options: [
              { id: 'Full time', name: 'Full time' },
              { id: 'Part time', name: 'Part time' },
              { id: 'None', name: 'None' }
            ],
            selected: {},
            multiple: false
          },
          {
            type: 'select',
            dataType: 'string',
            title: 'Errors',
            tooltip: 'Time logs errors',
            name: 'timelog_errors',
            trackby: 'id',
            label: 'label',
            options: this.$constants.timeLogErrorsOptions,
            multiple: true,
            selected: {}
          },

          {
            type: 'daterange',
            dataType: 'datetime',
            defaultRange: 'This week',
            title: 'Period',
            tooltip: 'Payroll period',
            name: 'period'
          }
        ]
      },
      reportTotal: {
        totalReimbursement: 0,
        totalGrossPay: 0,
        totalPay: 0,
        totalOvertime: 0,
        totalRegularHours: 0,
        totalOvertimeHours: 0,
        totalPayAdditions: 0,
        totalBonusPayments: 0
      },

      usersData: [],

      salaryTableColumns: [
        {
          key: 'salaryreluserid',
          label: 'ID'
        },
        {
          key: 'last_name',
          label: 'Last name'
        },
        {
          key: 'first_name',
          label: 'First name'
        },
        {
          key: 'rolename',
          label: 'Job role'
        },
        {
          key: 'groupname',
          label: 'Group'
        },
        {
          key: 'salaryrevisiondate',
          label: 'Revision'
        },
        {
          key: 'hourlyRate',
          label: 'Hourly'
        },
        {
          key: 'yearlyRate',
          label: 'Salary'
        },
        {
          key: 'yearlyCombined',
          label: 'Yearly'
        },
        {
          key: 'category',
          label: 'Exempt'
        },
        {
          key: 'empStatus',
          label: 'Status'
        },
        {
          key: 'salaryinsurance',
          label: 'Insurance'
        },
        {
          key: 'salaryactive',
          label: 'Active'
        }
      ],
      detailsColumns: [
        {
          key: 'week_num',
          label: 'Week num'
        },
        {
          key: 'week_day',
          label: 'Week day'
        },
        {
          key: 'track_date',
          label: 'Date'
        },

        {
          key: 'trackid',
          label: 'Track ID'
        },
        {
          key: 'day_type',
          label: 'Date type'
        },
        {
          key: 'time_stamp1',
          label: 'From'
        },
        {
          key: 'time_stamp2',
          label: 'To'
        },
        {
          key: 'tracked_hours',
          label: 'Hours'
        },
        {
          key: 'lunch_hours',
          label: 'Lunch'
        },
        {
          key: 'error',
          label: ''
        },
        {
          key: 'actions',
          label: ''
        }

        /*
                {
                    key: 'location',
                    label: 'Location'
                },
                {
                    key: 'notes',
                    label: 'Notes'
                },
                */
      ]
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),

    lastPayrollDate () {
      let lastPayrollPeriod = this.$helpers
        .getBiWeeklyDatesArray(
          moment()
            .clone()
            .subtract(1, 'month'),
          moment(),
          false
        )
        .reverse()
        .find(d => moment(d.endDate).isBefore(moment()))

      let lastPayrollDate = moment(lastPayrollPeriod.endDate)
        .clone()
        .isoWeekday(5)
        .format('YYYY-MM-DD')

      return lastPayrollDate
    }
  },
  created () {
    if (this.$route.fullPath == '/payroll/my-payroll' || this.isWidget) {
      this.mode = 'mypayroll'
    } else {
      this.mode = 'payroll'
    }

    if (this.mode == 'mypayroll') {
      //remove users filter
      this.filteringPanel.filters = this.filteringPanel.filters.filter(
        f => f.name != 'user_name'
      )
    }
  },
  mounted () {
    //if called by Payroll calendar set payroll period
    if (this.startDate && this.endDate) {
      this.$refs['filteringPanel'].setValue('period', 'daterange', {
        startDate: this.startDate,
        endDate: this.endDate
      })
    }

    if (this.run) {
      this.onFilteringPanelSearch(this.$refs['filteringPanel'].selected)
    }
  },
  methods: {
    ...mapActions('timeTracker', ['deleteTimeTrack']),
    hideEmptyUsers (reportData) {
      //return false;
      return reportData.total.totalGrossPay == 0
    },
    getDate (value) {
      return moment(value).format('YYYY-MM-DD')
    },

    updateDateField (e, data) {
      let self = this

      let date = e.valueSingleAsString.split(' ')[0]
      let time = e.valueSingleAsString.split(' ')[1]

      let payload = {
        trackid: data.item.trackid,
        fromDate: data.field.label == 'From' ? date : '',
        fromTime: data.field.label == 'From' ? time : '',
        toDate: data.field.label == 'To' ? date : '',
        toTime: data.field.label == 'To' ? time : ''
      }

      this.$api.put('timetracker/time', payload).then(response => {
        self.updateUserReport(data.item.trackuserid)

        self.$form.makeToastInfo(response.message)
      })
    },
    onDictionariesLoaded () {
      //this.onFilteringPanelSearch();
    },
    async loadDictionaries () {
      if (this.mode == 'payroll') {
        //this.$api.post('users', payload).then(users => {
        this.$api.get('users/all').then(users => {
          this.filteringPanel.filters.find(
            f => f.name == 'user_name'
          ).options = [
            ...users.map(u => ({
              id: u.id,
              name: u.Name
            }))
          ]
        })
        this.$api.get('roles').then(roles => {
          this.filteringPanel.filters.find(
            f => f.name == 'filtrole'
          ).options = [
            ...roles.map(u => ({
              id: u.roleid,
              name: u.rolename
            }))
          ]
        })
        /*
        this.$api
          .get("groups")
          .then(groups => {
            this.filteringPanel.filters.find(
              f => f.name == "filtgroup"
            ).options = [
              ...groups.map(u => ({
                id: u.id,
                name: u.groupname
              }))
            ];
          })
          .then(() => this.filteringPanel.loaded);
          */
      }
    },
    addLunch (value) {
      let day = value.item
      let payload = {
        trackid: day.trackid
      }

      this.$api
        .post('timetracker/users/payroll/lunch', payload)
        .then(async () => {
          day.lunch_hours = 0.5
          day.errors = day.errors.filter(
            e => e != this.$constants.TIMELOG_ERRORS.NO_LUNCH
          )
          day.error = day.errors
            .map(
              e =>
                this.$constants.timeLogErrorsOptions.find(t => t.id == e).label
            )
            .join(', ')
          day._rowVariant = ''

          this.updateUserReport(value.item.trackuserid)

          /*20201203 moved to this.updateUserReport
                this.rawData.payroll = await this.getPayrollData()

                let s = this.rawData.salaries.find(s => s.salaryreluserid == value.item.trackuserid)
                let p = this.rawData.payroll.filter(p => p.trackuserid == value.item.trackuserid)

                let payload = {
                    salary: s,
                    payroll: p
                }

                this.drawUserReport(payload)

                this.updateReportTotal()
                */
        })
        .catch(e => {
          console.log(e)
        })
    },
    onAddTimeClick (time_data, user_data) {
      const user = {
        id: user_data[0].salaryreluserid,
        full_name: user_data[0].full_name
      }

      this.$refs.addTimeDialog.show(
        this.$constants.TRACK_TIME_ACTIONS.ADD_TIME,
        { day: time_data.track_date },
        user
      )
    },
    async onTimeDialogSave (_payload) {
      this.updateUserReport(_payload.user_id)
    },
    async onDeleteTimeClick (_time_data, _report_data) {
      const user_data = _report_data.salaryTable.data[0]
      //const weeks = _report_data.weeks

      let confirm = await this.$form.showConfirmation(
        `Time log #${_time_data.trackid} will be deleted. Do you want to proceed?`
      )

      if (!confirm) return

      let self = this

      this.deleteTimeTrack(_time_data.trackid)
        .then(async response => {
          self.$form.makeToastInfo(response.message)

          self.updateUserReport(user_data.salaryreluserid)
        })
        .catch(error => {
          self.$form.makeToastError(error.message)
        })

      /*

let week = weeks.find( w => w.week_num == _time_data.week_num)
let weekIndex = weeks.findIndex( w => w.week_num == _time_data.week_num)

let day = week.details.find( d => d.track_date == _time_data.track_date)
let dayIndex = week.details.findIndex( d => d.track_date == _time_data.track_date)

const emptyDay = {
    day_type: "",
    track_date: _time_data.track_date,
    errors:[1],
    week_num: _time_data.week_num,
    week_day: _time_data.week_day,
    _rowVariant: "danger",
    error: "Missed day"
    }

day = emptyDay

Vue.set(week.details, dayIndex,day );
*/
    },
    onFilteringPanelChange (e) {
      this.filteringPanel.selected = e

      if (
        this.filteringPanel.selected['period'] &&
        this.filteringPanel.selected['period'].changed == true
      ) {
        this.filteringPanel.selected['period'].changed = false
      } else {
        setTimeout(() => {
          this.drawFullReport()
        }, 500)

        this.isLoading = true
      }
    },
    async drawFullReport () {
      let self = this

      if (!self.rawData.salaries) return

      let userId = 0,
        rolename = '',
        //groupname = "Sales Manager",
        statusname = '',
        categoryname = ''

      if (this.mode == 'mypayroll') {
        userId = this.profile.data.id
      }

      if (this.mode == 'payroll') {
        userId = this.filteringPanel.selected['user_name']
          ? this.filteringPanel.selected['user_name'].id
          : 0

        if (this.filteringPanel.selected['filtrole']) {
          rolename = this.filteringPanel.selected['filtrole'].name
        }
        if (this.filteringPanel.selected['filtcat']) {
          categoryname = this.filteringPanel.selected['filtcat'].name
        }
        if (this.filteringPanel.selected['filtempstatus']) {
          statusname = this.filteringPanel.selected['filtempstatus'].name
        }
        //  if (this.filteringPanel.selected["filtgroup"]) {
        //    groupname = this.filteringPanel.selected["filtgroup"].name;
        //  }
      }

      //filter by user
      let filteredData =
        userId > 0
          ? self.rawData.salaries.filter(
              s => s.salaryreluserid.toString() == userId.toString()
            )
          : this.rawData.salaries

      if (rolename)
        filteredData = filteredData.filter(s => s.rolename == rolename)

      // if (groupname)
      //    filteredData = filteredData.filter(s => s.groupname == groupname);
      if (categoryname)
        filteredData = filteredData.filter(s => s.category == categoryname)

      if (statusname)
        filteredData = filteredData.filter(s => s.empStatus == statusname)

      this.usersData = []

      //filteredData = filteredData.filter(s => s.user_status == "Active");

      filteredData.forEach(s => {
        let p = self.rawData.payroll.filter(
          p => p.trackuserid == s.salaryreluserid
        )

        // if (p.length > 0 && s.salaryactive == 'Yes') {
        //   if (s.salaryactive == 'Yes') {

        //101223
        //if ((s.category == "Non-exempt" && p.length > 0) || s.category == "Exempt")

        let payload = {
          salary: s,
          payroll: p
        }

        this.drawUserReport(payload)
      })

      this.updateReportTotal()

      this.isLoading = false
    },
    async updateUserReport (user_id) {
      this.rawData.payroll = await this.getPayrollData()

      let s = this.rawData.salaries.find(s => s.salaryreluserid == user_id)
      let p = this.rawData.payroll.filter(p => p.trackuserid == user_id)

      let payload = {
        salary: s,
        payroll: p
      }

      this.drawUserReport(payload)

      this.updateReportTotal()
    },
    updateReportTotal () {
      this.reportTotal = {
        totalReimbursement: 0,
        totalGrossPay: 0,
        totalPay: 0,
        totalOvertime: 0,
        totalRegularHours: 0,
        totalOvertimeHours: 0,
        totalPayAdditions: 0,
        totalBonusPayments: 0,
        totalExempt: 0,
        totalNonExempt: 0
      }

      this.usersData.forEach(item => {
        this.reportTotal.totalPayAdditions += item.total.totalPayAdditions
        this.reportTotal.totalBonusPayments += item.total.totalBonusPayments
        this.reportTotal.totalReimbursement += item.total.totalReimbursement
        this.reportTotal.totalGrossPay += item.total.totalGrossPay
        this.reportTotal.totalPay += item.total.totalPay
        this.reportTotal.totalOvertime += item.total.totalOvertime
        this.reportTotal.totalRegularHours += item.total.totalRegularHours
        this.reportTotal.totalOvertimeHours += item.total.totalOvertimeHours
      })

      this.reportTotal.totalExempt = this.rawData.salaries_not_in_payroll.length
      this.reportTotal.totalNonExempt = this.rawData.salaries.length
      this.reportTotal.totalRegularHours =
        (
          this.reportTotal.totalRegularHours +
          this.rawData.salaries_not_in_payroll.length * 80
        ).toFixed(2) +
        ` (${this.reportTotal.totalRegularHours.toFixed(2)} exempt +${this
          .rawData.salaries_not_in_payroll.length * 80} non-exempt)`
    },
    onFilteringPanelSearch: function (e) {
      this.filteringPanel.selected = e

      let self = this

      this.getData().then(response => {
        self.rawData = response

        self.drawFullReport()
      })
    },
    onFilteringPanelReset: function () {},
    getData: async function () {
      let self = this

      let userId = 0

      if (this.mode == 'mypayroll') userId = this.profile.data.id

      let response = {}

      let data = {
        period: this.filteringPanel.selected['period']
      }

      if (userId !== 0) data.userId = userId

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async function (fulfill) {
        self.isLoading = true

        response.salaries = await self.$api.get('timetracker/users/salaries')

        response.salaries_not_in_payroll = response.salaries.filter(
          s => +s.inpayroll !== 1 && s.category == 'Non-exempt'
        )

        response.salaries = response.salaries.filter(s => +s.inpayroll == 1)

        response.reimbursements = await self.$api.post('reimbursements', data)
        //console.log('getData.response.reimbursements', response.reimbursements)
        response.bonusPayments = await self.$api.post('bonus-payments', data)
        //console.log('getData.response.bonusPayments', response.bonusPayments)
        await self.$api
          .post('timetracker/users/payroll', data)
          .then(payroll => {
            self.isLoading = false

            response.payroll = payroll

            //console.log('getData', response.payroll)

            self.$emit('load')

            fulfill(response)
          })
      })
    },
    async getPayrollData () {
      let data = {
        period: this.filteringPanel.selected['period']
      }

      return await this.$api.post('timetracker/users/payroll', data)
    },
    getCalendar () {
      let minDate = moment(
        this.filteringPanel.selected['period'].startDate
      ).isoWeekday(1)
      let maxDate = moment(
        this.filteringPanel.selected['period'].endDate
      ).isoWeekday(7)

      let range = moment.range(minDate, maxDate)
      let weeks = []
      for (let mday of range.by('days')) {
        //if (weeks.indexOf(mday.isoWeek()) == -1) {
        if (
          !weeks.some(
            week => week[0] == mday.isoWeek() && week[1] == mday.isoWeekYear()
          )
        ) {
          let week = [mday.isoWeek(), mday.isoWeekYear()]

          weeks.push(week)
        }
      }

      let calendar = [],
        firstWeekDay = undefined,
        lastWeekDay = undefined

      for (let index = 0; index < weeks.length; index++) {
        let weeknumber = weeks[index][0]
        let weekYear = weeks[index][1]

        //const dty = (lastWeekDay ? lastWeekDay : minDate).format("YYYY-MM-DD");

        //firstWeekDay = moment.utc(dt).day("Monday").isoWeek(weeknumber);
        //lastWeekDay = moment.utc(dt).day("Sunday").isoWeek(weeknumber);

        firstWeekDay = moment()
          .isoWeekYear(weekYear)
          .isoWeekday(1)
          .isoWeek(weeknumber)

        lastWeekDay = moment()
          .isoWeekYear(weekYear)
          .isoWeekday(7)
          .isoWeek(weeknumber)

        //////////

        //let weekRange = moment.range(firstWeekDay, lastWeekDay)
        let weekRange = {
          start: moment.utc(firstWeekDay.format('YYYY-MM-DD')),
          end: moment.utc(lastWeekDay.format('YYYY-MM-DD'))
        }

        calendar.push(weekRange)
      }

      return calendar
    },
    drawUserReport (payload) {
      let reportData = {
        weeks: [],
        salary: {},
        salaryTable: {
          data: []
        },
        total: {
          totalReimbursement: 0,
          totalPayAdditions: 0,
          totalBonusPayments: 0,
          totalGrossPay: 0,
          totalPay: 0,
          totalOvertime: 0,
          totalRegularHours: 0,
          totalOvertimeHours: 0,
          totalVacationHours: 0,
          totalVacationPaidHours: 0,
          totalVacationUnpaidHours: 0,
          totalPLAHours: 0,
          totalPLAPaidHours: 0,
          totalPLAUnpaidHours: 0,
          totalSickPaidHours: 0,
          totalSickUnPaidHours: 0,
          totalHolidayHours: 0
        }
      }

      payload.salary.hourlyRate = parseFloat(payload.salary.salaryratehour)
      payload.salary.yearlyRate = parseFloat(payload.salary.salaryrateyear)
      //payload.salary.yearlyCombined = (payload.salary.yearlyRate > 0 ? payload.salary.yearlyRate : payload.salary.hourlyRate * 2080).toFixed(2)
      payload.salary.yearlyCombined = (payload.salary.category == 'Exempt'
        ? payload.salary.yearlyRate
        : payload.salary.hourlyRate * 2080
      ).toFixed(2)

      reportData.salary = payload.salary

      //reportData.salaryTable.data = {...[]}
      reportData.salaryTable.data.push(payload.salary)

      reportData.weeks = []

      let calendar = this.getCalendar()

      for (let week of calendar) {
        let weekdata = payload.payroll.filter(
          i =>
            moment(i.track_date) >= week.start &&
            moment(i.track_date) <= week.end
        )

        let weekData = {
          week_num: week.start.isoWeek(),
          visible: false,
          period: `${week.start.format('LL')} - ${week.end.format(
            'LL'
          )}, Week ${week.start.isoWeek()}`,
          regularHours: 0,
          lunchHours: 0,
          overtimeHours: 0,
          sickPaidHours: 0,
          sickUnpaidHours: 0,
          vacationHours: 0,
          vacationPaidHours: 0,
          vacationUnpaidHours: 0,
          PLAHours: 0,
          PLAPaidHours: 0,
          PLAUnpaidHours: 0,
          holidayHours: 0,
          weeklyPay: 0,
          pay: 0,
          overtimePay: 0,
          grossPay: 0,
          reimbursement: 0,
          payAdditions: 0,
          bonusPayments: 0,
          details: [],
          errors: []
        }

        let timeIntervals = []

        //add 5 empty days
        let emptyWeek = []
        for (let ed = 0; ed < 5; ed++) {
          let day = {
            day_type: '',
            track_date: moment(week.start)
              .clone()
              .add(ed, 'day')
              .format('YYYY-MM-DD'),
            errors: []
          }
          day.week_num = moment(day.track_date).isoWeek()
          day.week_day = moment(day.track_date).format('dddd')

          emptyWeek.push(day)
        }

        //update with existing timelog data
        for (let day of weekdata) {
          day.errors = []

          //check for duplicates
          if (
            timeIntervals.find(
              t =>
                t.track_date == day.track_date &&
                t.time_stamp1 == day.time_stamp1 &&
                t.time_stamp2 == day.time_stamp2 &&
                t.day_type == day.day_type
            )
          ) {
            day.duplicate = true

            this.validateErrors(day)
          }

          //check intervals overlapping

          if (
            !day.duplicate &&
            timeIntervals.find(
              t =>
                t.track_date == day.track_date &&
                ((moment(t.time_stamp1).isSameOrBefore(
                  moment(day.time_stamp1)
                ) &&
                  moment(t.time_stamp2).isSameOrAfter(
                    moment(day.time_stamp1)
                  )) || // t1 d1 t2
                (moment(t.time_stamp1).isSameOrBefore(
                  moment(day.time_stamp1)
                ) &&
                  moment(t.time_stamp2).isSameOrAfter(
                    moment(day.time_stamp2)
                  )) || // t1 d1 d2 t2
                (moment(t.time_stamp1).isSameOrBefore(
                  moment(day.time_stamp2)
                ) &&
                  moment(t.time_stamp2).isSameOrAfter(
                    moment(day.time_stamp2)
                  )) || // t1 d2 t2
                  (moment(t.time_stamp1).isSameOrAfter(
                    moment(day.time_stamp1)
                  ) &&
                    moment(t.time_stamp2).isSameOrBefore(
                      moment(day.time_stamp2)
                    ))) // d1 t1 t2 d2
            )
          ) {
            day.overlapping = true

            this.validateErrors(day)
          }

          timeIntervals.push({
            track_date: day.track_date,
            day_type: day.day_type,
            time_stamp1: day.time_stamp1,
            time_stamp2: day.time_stamp2
          })

          day.tracked_hours = +parseFloat(day.tracked_hours || 0).toFixed(2)
          day.lunch_hours = +parseFloat(day.lunch_hours || 0).toFixed(2)
          day.week_num = moment(day.track_date).isoWeek()
          day.week_day = moment(day.track_date).format('dddd')

          if (day.day_type == 'Regular' || day.day_type == 'Forgotten') {
            weekData.regularHours += day.tracked_hours - day.lunch_hours
            weekData.lunchHours += day.lunch_hours
            this.validateErrors(day)
          }
          //  console.log('day.track_date', day.track_date, day)

          if (day.day_type == 'Sick') {
            weekData.sickPaidHours += parseFloat(day.sick_paid_hours || 0)
            weekData.sickUnpaidHours += parseFloat(day.sick_unpaid_hours || 0)
            day.tracked_hours = `${day.tracked_hours} (${day.sick_paid_hours}/${day.sick_unpaid_hours} )`
            this.validateErrors(day)
          }

          if (day.day_type == 'Vacation' && day.vacation_approved == 1) {
            weekData.vacationPaidHours += parseFloat(
              day.vacation_hours_paid || 0
            )
            weekData.vacationUnpaidHours += parseFloat(
              day.vacation_hours_unpaid || 0
            )
            day.tracked_hours = `${day.tracked_hours} (${day.vacation_hours_paid}/${day.vacation_hours_unpaid} )`

            this.validateErrors(day)
          }

          if (day.day_type == 'Vacation' && day.vacation_approved == 0) {
            day.tracked_hours = `${day.tracked_hours} (declined)`
          }

          if (day.day_type == 'PLA' && day.vacation_approved == 1) {
            weekData.PLAPaidHours += parseFloat(day.vacation_hours_paid || 0)
            weekData.PLAUnpaidHours += parseFloat(
              day.vacation_hours_unpaid || 0
            )
            day.tracked_hours = `${day.tracked_hours} (${day.vacation_hours_paid}/${day.vacation_hours_unpaid} )`

            this.validateErrors(day)
          }
          if (day.day_type == 'PLA' && day.vacation_approved == 0) {
            day.tracked_hours = `${day.tracked_hours} (declined)`
          }

          if (day.day_type == 'Holiday') {
            weekData.holidayHours += parseFloat(day.tracked_hours || 0)
          }

          // find and update week day

          //let w =  weekData.details.find( w => w.track_date == day.track_date)
          //weekData.details[weekData.details.indexOf(w)] = day
          weekData.details.push(day)
        } // for (let day of weekdata)

        //get not existing in the timelog days
        let notExistingWeekDays = emptyWeek.filter(
          d => !weekData.details.find(w => w.week_day == d.week_day)
        )

        notExistingWeekDays.forEach(d => this.validateErrors(d))

        //concatenate existing and empty days
        weekData.details = weekData.details.concat(notExistingWeekDays)
        //sort by date asc
        weekData.details = weekData.details.sort(function (a, b) {
          return new Date(a.track_date) - new Date(b.track_date)
        })

        //collect distinct errors from week days
        weekData.details.forEach(w => {
          weekData.errors = [...new Set([...weekData.errors, ...w.errors])]
        })

        //calculate totals
        if (
          payload.salary.category !== 'Exempt' &&
          weekData.regularHours > 40
        ) {
          weekData.overtimeHours = weekData.regularHours - 40
          weekData.regularHours = 40
        }

        weekData.totalPaidHours =
          weekData.regularHours +
          weekData.sickPaidHours +
          weekData.vacationPaidHours +
          weekData.PLAPaidHours +
          weekData.holidayHours
        weekData.totalUnpaidHours =
          weekData.vacationUnpaidHours +
          weekData.PLAUnpaidHours +
          weekData.sickUnpaidHours +
          weekData.lunchHours
        weekData.pay =
          payload.salary.category == 'Exempt'
            ? 0
            : weekData.totalPaidHours * reportData.salary.hourlyRate

        //weekData.weeklyPay = reportData.salary.yearlyRate ? (reportData.salary.yearlyRate / 2080 * 40) : 0
        weekData.weeklyPay =
          payload.salary.category == 'Exempt'
            ? (reportData.salary.yearlyRate / 2080) * 40
            : 0

        if (payload.salary.category !== 'Exempt' && weekData.overtimeHours > 0)
          weekData.overtimePay =
            weekData.overtimeHours * reportData.salary.hourlyRate * 1.5

        weekData.grossPay =
          weekData.pay + weekData.overtimePay + weekData.weeklyPay

        let rmb = this.getWeeklyReimbursements(
          week,
          payload.salary.salaryreluserid
        )

        let bpm = this.getWeeklyBonusPayments(
          week,
          payload.salary.salaryreluserid
        )
        weekData.reimbursement = rmb.reimbursement
        weekData.payAdditions = rmb.payAdditions
        weekData.bonusPayments = bpm.bonusPayments

        reportData.total.totalPayAdditions += weekData.payAdditions
        reportData.total.totalBonusPayments += weekData.bonusPayments
        reportData.total.totalReimbursement += weekData.reimbursement
        reportData.total.totalGrossPay += weekData.grossPay
        reportData.total.totalPay += weekData.pay + weekData.weeklyPay
        reportData.total.totalOvertime += weekData.overtimePay
        reportData.total.totalRegularHours += weekData.totalPaidHours
        reportData.total.totalOvertimeHours += weekData.overtimeHours

        reportData.total.totalVacationHours += weekData.vacationHours
        reportData.total.totalVacationPaidHours += weekData.vacationPaidHours
        reportData.total.totalVacationUnpaidHours +=
          weekData.vacationUnpaidHours

        reportData.total.totalPLAHours += weekData.PLAHours
        reportData.total.totalPLAPaidHours += weekData.PLAPaidHours
        reportData.total.totalPLAUnpaidHours += weekData.PLAUnpaidHours

        reportData.total.totalSickPaidHours += weekData.sickPaidHours
        reportData.total.totalSickUnPaidHours += weekData.sickUnpaidHours
        reportData.total.totalHolidayHours += weekData.holidayHours

        //add weeks with errors
        if (
          this.filteringPanel.selected['timelog_errors'] &&
          this.filteringPanel.selected['timelog_errors'].length > 0
        ) {
          this.filteringPanel.selected['timelog_errors'].forEach(e => {
            if (
              weekData.errors.includes(e.id) &&
              !reportData.weeks.find(w => w.week_num == weekData.week_num)
            ) {
              reportData.weeks.push(weekData)
            }
          })
        } else {
          //add all weeks

          reportData.weeks.push(weekData)
        }
      }

      if (reportData.weeks.length > 0) {
        //update existing item (if lunch added for example) or add new
        let idx = this.usersData.findIndex(
          i => i.salary.salaryreluserid == reportData.salary.salaryreluserid
        )

        if (idx > -1) {
          this.usersData[idx] = reportData
          this.$forceUpdate()
        } else this.usersData.push(reportData)
      }
    },
    getWeeklyReimbursements (week, user_id) {
      let rmb = this.rawData.reimbursements.filter(
        i =>
          moment(i.track_date) >= week.start &&
          moment(i.track_date) <= week.end &&
          i.user_id == user_id
      )

      let totalPayAdditions = 0
      let totalReimbursement = 0

      rmb.forEach(i => {
        totalPayAdditions += parseFloat(i.reimbursementpayaddition || 0)
        totalReimbursement += parseFloat(i.reimbursementtotal || 0)
      })

      return {
        reimbursement: totalReimbursement,
        payAdditions: totalPayAdditions
      }
    },
    getWeeklyBonusPayments (week, user_id) {
      let bpm = this.rawData.bonusPayments.filter(
        i =>
          moment(i['Bonus Date']) >= week.start &&
          moment(i['Bonus Date']) <= week.end &&
          i['User ID'] == user_id &&
          i['Amount'] > 0
      )

      let totalBonusPayments = 0

      bpm.forEach(i => {
        totalBonusPayments += parseFloat(i['Amount'] || 0)
      })

      return {
        bonusPayments: totalBonusPayments
      }
    },
    validateErrors (day) {
      if (day.day_type == '') {
        day.errors.push(this.$constants.TIMELOG_ERRORS.MISSED_DAY)
        day._rowVariant = 'danger'
      }

      if (day.day_type == 'Regular' || day.day_type == 'Forgotten') {
        if (day.tracked_hours > 7 && day.lunch_hours == 0) {
          day.errors.push(this.$constants.TIMELOG_ERRORS.NO_LUNCH)
          day._rowVariant = 'danger'
        } else if (
          day.tracked_hours > 1 &&
          day.tracked_hours < 7 &&
          day.lunch_hours == 0
        ) {
          day.errors.push(this.$constants.TIMELOG_ERRORS.NO_LUNCH)
        }

        if (day.lunch_cnt > 1) {
          day.errors.push(this.$constants.TIMELOG_ERRORS.DOUBLE_BREAKS)
          day._rowVariant = 'danger'
        }
      }

      if (day.tracked_hours > 10) {
        day.errors.push(this.$constants.TIMELOG_ERRORS.EXTRA_LONG_CLOCK)
        day._rowVariant = 'danger'
      }

      if (day.duplicate) {
        //day.errors.push(TIMELOG_ERRORS.DOUBLE_DAYS)
        day.errors = [
          ...new Set([
            ...day.errors,
            ...[this.$constants.TIMELOG_ERRORS.DOUBLE_DAY]
          ])
        ]
        day._rowVariant = 'danger'
      }

      if (day.overlapping) {
        //day.errors.push(TIMELOG_ERRORS.OVERLAPPING_CLOCKS)
        day.errors = [
          ...new Set([
            ...day.errors,
            ...[this.$constants.TIMELOG_ERRORS.OVERLAPPING_CLOCKS]
          ])
        ]
        day._rowVariant = 'danger'
      }

      if (day.day_type == 'Sick') {
        if (day.sick_paid_hours > 8) {
          day.errors.push(this.$constants.TIMELOG_ERRORS.SICK_OVERTIME)
          day._rowVariant = 'danger'
        }
      }
      if (day.day_type == 'Vacation' && day.vacation_approved == 1) {
        if (day.tracked_hours > 8) {
          day.errors.push(this.$constants.TIMELOG_ERRORS.VACATION_OVERTIME)
          day._rowVariant = 'danger'
        }
      }
      if (day.day_type == 'Vacation' && day.vacation_approved == 1) {
        if (day.vacation_hours_unpaid > 0) {
          day.errors.push(this.$constants.TIMELOG_ERRORS.UNPAID_VACATION)
          day._rowVariant = 'danger'
        }
      }

      day.error = day.errors
        .map(
          e => this.$constants.timeLogErrorsOptions.find(t => t.id == e).label
        )
        .join(', ')
    }
  },
  watch: {}
}
</script>

<style scoped>
.user-header-print {
  display: none;
}

@media print {
  .user-header-no-print {
    display: none;
  }

  .user-header-print {
    display: block;
  }

  .exclude-print {
    display: none;
  }

  .thead-dark {
    display: none;
  }

  .page-break {
    page-break-before: always;
  }

  .avoid-page-break {
    page-break-inside: avoid;
  }
}
</style>
